import React from "react";
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

const sendEnquiry = (email: string, name: string, comment: string) => {
    let xhr = new XMLHttpRequest();
    let url = "https://ir3guezem4.execute-api.eu-west-2.amazonaws.com/production/contact-us";

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            console.log("Response: " + JSON.parse(xhr.responseText));
        }
    };

    let data = JSON.stringify(
        {
            email: email,
            name: name,
            comment: comment
        }
    );

    console.log(data);
    xhr.send(data);
};

const Contact = () => {
    let self = this;
    return <StandardLayout>

        <Helmet>
            <title>Contact Us - Burke Shipping Services</title>
        </Helmet>

        <div className="c-hero" style={{backgroundImage: "url(/images/shipping-hero-1.jpg)"}} >

        </div>


        <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-5">

                <h1 className="c-heading +h1 u-marg-bottom-large u-align-center">Contact</h1>

                <div className="l-grid +cols-1 +cols-2@m +gap-2">

                    <div className="l-grid__item u-align-center">

                        <div className="u-marg-bottom-large">
                            <h3 className="c-heading u-font-bold +h3 u-marg-bottom">Address</h3>
                            <p>Burke Shipping Services Limited<br />
                                Port Office<br />
                                Lisahally Terminal<br />
                                Londonderry<br />
                                Northern Ireland <br />
                                BT47 6FL</p>
                        </div>

                        <ul className="u-marg-bottom-large">
                            <li><span className="u-font-bold">Tel:</span> <a href="tel:+4402871860990" className="u-color-white"> +44 (0) 28 7186 0990</a></li>
                            <li><span className="u-font-bold">E-mail:</span> <a href="mailto:agency@burkeshipping.co.uk" className="u-color-white">agency@burkeshipping.co.uk</a></li>
                        </ul>

                    </div>

                    <div className="l-grid__item u-align-center">

                        <h3 className="c-heading u-font-bold +h3 u-marg-bottom-large">Out of Hours Contacts:</h3>

                        <div className="u-marg-bottom">
                            <p className="u-font-bold u-marg-bottom">Operations Director</p>
                            <p>Gerald McGahey Mob: <span className="u-nowrap"><br/><a href="tel:+4407711737576" className="u-color-white">+44 (0) 7711737576</a></span></p>
                        </div>

                        <p className="u-font-bold u-marg-bottom">Agency</p>
                        <p>Andrew Nutt Mob: <span className="u-nowrap"><br/><a href="tel:+4407793526966" className="u-color-white">+44 (0) 7793526966</a></span></p>

                    </div>

                </div>





            </div>
        </div>



        <div className="c-media-box +small">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2286.2912828490216!2d-7.268721683420128!3d55.0381119803682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486009dc858e52f1%3A0xa9fa0409aa698b29!2sBurke%20Shipping%20Services%20Ltd!5e0!3m2!1sen!2suk!4v1590107132222!5m2!1sen!2suk"
                width="600" height="450" frameBorder="0" aria-hidden="false"></iframe>
        </div>


        <div className="c-splitter"></div>

    </StandardLayout>
};

export default Contact;